import React from 'react';
import cookie from 'react-cookies';
import ReactTooltip from 'react-tooltip';

import { updateFavorites } from '../../../utils/FavoriteLocations';
import Portal from '../Portal';
import Notification from '../Notification';
import { SvgIco } from "../Icons";

import './index.sass';


export class FavouriteIcon extends React.PureComponent {

  state = {
    policyIsAccepted: cookie.load('cookie_consent_shown'),
    favoritesLimitExceededError: false,
    showLimitExceedMessage: false,
    favouritePopUp: false,
    isFavourite: false,
  };

  constructor(props) {
    super(props);
    this.typingTimer = null;
  }

  componentDidMount() {
    this.handleIsFavourite();
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { favoritesLimitExceededError, showLimitExceedMessage } = this.state;

    favoritesLimitExceededError && favoritesLimitExceededError !== prevState.favoritesLimitExceededError && this.showFavesLimitExceedMessage();
    this.props.favoriteLocations !== prevProps.favoriteLocations && this.handleIsFavourite();
    showLimitExceedMessage && showLimitExceedMessage !== prevState.showLimitExceedMessage && document.addEventListener('click', this.handleClickOutside);
  };

  handleClickOutside = () => {
    const { showLimitExceedMessage } = this.state;

    document.removeEventListener('click', this.handleClickOutside);
    showLimitExceedMessage && this.hideFavesLimitExceedMessage();
  }


  handleIsFavourite = () => {
    const { section, siteKey, locationId } = this.props;
    const sectionType = section === 'lease' ? section : 'sale';
    const retrievedFavouriteLocations = localStorage && localStorage.getItem(`my.favourite_${sectionType}_${siteKey}`);
    const favouriteLocationsIds = JSON.parse(retrievedFavouriteLocations) || [];
    const isFavourite = favouriteLocationsIds && favouriteLocationsIds.indexOf(`${locationId}`) >= 0;

    this.setState({ isFavourite });
  }


  handleClick = () => {
    const { favoritesLimitExceededError, isFavourite } = this.state;
    const { updateFavouriteLocations, section, siteKey, locationId, hideTooltip } = this.props;

    if (favoritesLimitExceededError && !isFavourite) {
      this.showFavesLimitExceedMessage();
    } else {
      const showInfoPopup = !(localStorage && localStorage.getItem(`my.favourite_message_shown`));
      const newFavoritesList = updateFavorites(locationId, section, siteKey, this.bounceHeart);

      showInfoPopup &&
      this.setState({ favouritePopUp: true }, () => {
        clearTimeout(this.typingTimer);
        localStorage && localStorage.setItem(`my.favourite_message_shown`, 'true')
        this.typingTimer = setTimeout(() => this.setState({ favouritePopUp: false }), 6000);
      });

      if (newFavoritesList.limitExceededError) {
        this.setState({ favoritesLimitExceededError: !this.state.favoritesLimitExceededError });
      } else {
        this.setState({ isFavourite: newFavoritesList.favouriteLocations.indexOf(`${locationId}`) >= 0 });
        updateFavouriteLocations && updateFavouriteLocations(newFavoritesList.favouriteLocations);
      }

      !hideTooltip && ReactTooltip.hide(this[`faveIcon${locationId}`]);
    }
  };


  bounceHeart = () => {
    const iconWrapper = document.getElementById(`faveWrapper${this.props.locationId}`);

    iconWrapper.classList.add('bounce');
    setTimeout(() => iconWrapper.classList.remove('bounce'), 1000);
  };


  showFavesLimitExceedMessage = () =>
    this.setState({ showLimitExceedMessage: true }, () => {
      this.typingTimer = setTimeout(() => this.setState({ showLimitExceedMessage: false }), 6000);
    });


  hideFavesLimitExceedMessage = () => {
    clearTimeout(this.typingTimer);
    this.setState({ showLimitExceedMessage: false });
  };


  closeFavouritePopUp = () => {
    clearTimeout(this.typingTimer);
    this.setState({ favouritePopUp: false });
    localStorage && localStorage.setItem(`my.favourite_message_shown`, 'true');
  };


  render() {

    const { favouritePopUp, policyIsAccepted, showLimitExceedMessage, isFavourite } = this.state;
    const { indentation, locationId, tooltipPosition, section, isDetailView, notificationWrapperId } = this.props;
    const tooltipText = isFavourite ? I18n.t('apps.lb_showroom.favourite_location.remove_tooltip') : I18n.t('apps.lb_showroom.favourite_location.add_tooltip');
    const sectionsI18n = section === 'lease' ? I18n.t('activerecord.models.location').zero : 'til salg';
    const limitExceedMessage = I18n.t('apps.lb_showroom.limited_faves_message', { section: sectionsI18n });


    return (
      <div className="favourite-icon">
        { isDetailView
          ? <div className="favourite-icon__with-label" onClick={this.handleClick}>
              <div className="favourite-icon__wrapper favourite-icon__wrapper--with-label" id={`faveWrapper${locationId}`}>
                { isFavourite
                  ? <SvgIco name="favorite" size={22} className="active"/>
                  : <SvgIco name="favorite_border" size={22} />
                }
              </div>
              <span className="favourite-icon__label">
                { I18n.t('apps.lb_showroom.favourite_location_label') }
              </span>
            </div>
          : <div role="button" className="favourite-icon__wrapper" id={`faveWrapper${locationId}`}>
              <div className="favourite-icon__click-area"
                   role="button"
                   onClick={this.handleClick}
                   aria-labelledby="favourite-icon"
                   style={indentation && {top: '32px'}}>
                <div className={`favourite-icon__icon${ isFavourite ? ' favourite' : '' }`}
                     data-tip={tooltipText}
                     data-class="favourite-icon__tooltip"
                     data-for={'faviIcon' + locationId}
                     ref={el => this[`faveIcon${ locationId }`] = el}>
                  <SvgIco name="favorite" size={18} className="active" />
                </div>

                { !isDetailView &&
                  <ReactTooltip place={ tooltipPosition } effect='solid' id={ 'faviIcon' + locationId }/>
                }
              </div>
            </div>
        }

        <Portal>
          <div ref={el => this.notificationWrapper = el}
               className="notifications-wrapper"
               id={notificationWrapperId}
               role="button"
               style={{ display: 'flex', justifyContent: 'center' }}>

            <Notification
              message={I18n.t('apps.lb_showroom.favourite_popup_new')}
              visible={favouritePopUp}
              customClass={!policyIsAccepted ? 'with-policy-block bottom-center' : 'bottom-center'}
              handleClose={this.closeFavouritePopUp} />

            <Notification
              message={limitExceedMessage}
              visible={showLimitExceedMessage}
              customClass="dark bottom-center"
              handleClose={this.hideFavesLimitExceedMessage} />
          </div>
        </Portal>

      </div>
    )
  }
}


export default FavouriteIcon;
