import React, { Component } from 'react';
import "core-js/stable";
import "regenerator-runtime/runtime";
import scrollTo from 'scroll-to';
import { SvgIco } from '../SharedComponents/Icons';
import './Buttons.sass';


export class ToTheTopBtn extends Component {

  showBtnAtPosition = 900;

  state = {
    isDisplaying: false,
  };

  componentDidMount() {
    this.handleScroll();
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    const isDisplaying = !!(window.pageYOffset > this.showBtnAtPosition);

    !!(window.pageYOffset > this.showBtnAtPosition) !== this.state.isDisplaying && this.setState({ isDisplaying: isDisplaying });
  };

  scrollTop() {
    scrollTo(0, 0, { duration: 400 });
  }

  render() {
    const wrapperClass = `back-to-top-btn buttons__wrapper${ !this.state.isDisplaying ? ' hidden' : ''}`;

    return (
      <div className={wrapperClass}
        title="Back to top"
        onClick={ this.scrollTop }
      >
        <SvgIco name="expand-less" size={32} />
      </div>
    );
  }
}
