export const changeAvailableLocalesLinks = (currentAvailableLocalesLinks, query_string) => {
  return currentAvailableLocalesLinks.map((availableLocalesLink) => ({
    ...availableLocalesLink,
    href:`${availableLocalesLink.href.split('?')[0]}?${query_string}`
  }))
};

export const handleFavoriteLocationsCounter = (siteKey, bounce) => {
  const favoriteLinks = [...document.querySelectorAll('.favorite-locations-link')];
  const favoriteCounters = [...document.querySelectorAll('.favorite-locations-link__counter')];
  const favoriteLeases = localStorage && JSON.parse(localStorage.getItem(`my.favourite_lease_${siteKey}`)) || [];
  const favoriteSales = localStorage && JSON.parse(localStorage.getItem(`my.favourite_sale_${siteKey}`)) || [];
  const favoriteCount = favoriteLeases.length + favoriteSales.length;

  if (favoriteCount) {
    favoriteLinks.forEach(link => {
      !link.classList.contains('active') && link.classList.add('active');
      if (bounce) {
        link.classList.add('bounce');
        setTimeout(() => link.classList.remove('bounce'), 1000);
      }
    });
    favoriteCounters.forEach(counter => counter.textContent = favoriteCount);
  } else {
    favoriteLinks.forEach(link => link.classList.contains('active') && link.classList.remove('active'));
  }
}

export const handleComparableLocationsCounter = (siteKey, bounce) => {
  const comparableIcons = [...document.querySelectorAll('.header-comparison-icon')];
  const comparableCounters = [...document.querySelectorAll('.header-comparison-icon__counter')];
  const comparableLeases = localStorage && JSON.parse(localStorage.getItem(`my.comparable_lease_${siteKey}`)) || [];
  const comparableCount = Object.keys(comparableLeases).reduce((accum, kind) => accum + comparableLeases[kind].length, 0);

  if (comparableCount) {
    comparableIcons.forEach(link => {
      !link.classList.contains('active') && link.classList.add('active');
      if (bounce) {
        link.classList.add('bounce');
        setTimeout(() => link.classList.remove('bounce'), 1000);
      }
    });
    comparableCounters.forEach(counter => counter.textContent = comparableCount);
  } else {
    comparableIcons.forEach(link => link.classList.contains('active') && link.classList.remove('active'));
  }
}

