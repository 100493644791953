export const toUnderscore = string => {
  return string.replace(/([A-Z])/g, $string => {
    return '_' + $string.toLowerCase();
  });
};

export const hasSomeParentTheClass = (el, classname) => {
  if (
    el.className &&
    typeof el.className === 'string' &&
    el.className.split(' ').indexOf(classname) >= 0
  ) {
    return true;
  } else if (el instanceof SVGElement) {
    return true;
  }

  return el.parentNode && hasSomeParentTheClass(el.parentNode, classname);
};

export const isMobile = () => window.innerWidth < 768;
export const isTablet = () => window.innerWidth < 768;
export const isDesktop = () => window.innerWidth < 992;

export const parseMd = (md) => {

  //images
  md = md.replace(/\!\[([^\]]+)\]\(([^\)]+)\)/g, '<img data-src="$2" alt="$1" class="lazyload" data-sizes="auto" data-expand="100" />');

  //ul
  md = md.replace(/^\s*\n\*/gm, '<ul>\n*');
  md = md.replace(/^(\*.+)\s*\n([^\*])/gm, '$1\n</ul>\n\n$2');
  md = md.replace(/^\*(.+)/gm, '<li>$1</li>');

  //ol
  md = md.replace(/^\s*\n\d\./gm, '<ol>\n1.');
  md = md.replace(/^(\d\..+)\s*\n([^\d\.])/gm, '$1\n</ol>\n\n$2');
  md = md.replace(/^\d\.(.+)/gm, '<li>$1</li>');

  //blockquote
  md = md.replace(/^\>(.+)/gm, '<blockquote>$1</blockquote>');

  //h
  md = md.replace(/[\#]{6}(.+)/g, '<h6>$1</h6>');
  md = md.replace(/[\#]{5}(.+)/g, '<h5>$1</h5>');
  md = md.replace(/[\#]{4}(.+)/g, '<h4>$1</h4>');
  md = md.replace(/[\#]{3}(.+)/g, '<h3>$1</h3>');
  md = md.replace(/[\#]{2}(.+)/g, '<h2>$1</h2>');
  md = md.replace(/[\#]{1}(.+)/g, '<h1>$1</h1>');

  //alt h
  md = md.replace(/^(.+)\n\=+/gm, '<h1>$1</h1>');
  md = md.replace(/^(.+)\n\-+/gm, '<h2>$1</h2>');

  //links
  md = md.replace(/[\[]{1}([^\]]+)[\]]{1}[\(]{1}([^\)\"]+)(\"(.+)\")?[\)]{1}/g, '<a href="$2" title="$4">$1</a>');

  //font styles
  md = md.replace(/[\*\_]{2}([^\*\_]+)[\*\_]{2}/g, '<b>$1</b>');
  // md = md.replace(/[\*\_]{1}([^\*\_]+)[\*\_]{1}/g, '<i>$1</i>');
  md = md.replace(/[\~]{2}([^\~]+)[\~]{2}/g, '<del>$1</del>');

  //pre
  md = md.replace(/^\s*\n\`\`\`(([^\s]+))?/gm, '<pre class="$2">');
  md = md.replace(/^\`\`\`\s*\n/gm, '</pre>\n\n');

  //code
  md = md.replace(/[\`]{1}([^\`]+)[\`]{1}/g, '<code>$1</code>');

  //p
  md = md.replace(/^\s*(\n)?(.+)/gm, function(m){
    return  /\<(\/)?(h\d|ul|ol|li|blockquote|pre|img)/.test(m) ? m : '<p>'+m+'</p>';
  });

  //strip p from pre
  md = md.replace(/(\<pre.+\>)\s*\n\<p\>(.+)\<\/p\>/gm, '$1$2');

  return md;
};

export const camelizeName = (name) => {
  return name.split(" ")
    .map((nameSubstring, index) => index === 0 || nameSubstring.length > 3 ? nameSubstring.substring(0, 1).toUpperCase() + nameSubstring.substring(1) : nameSubstring)
    .join(' ')
};


export const localizedNumberFormatter = (value, currency) => {

  const locale = I18n.locale === 'en' ? 'fr' : I18n.locale;

  const formatter = currency
    ? new Intl.NumberFormat(locale, {
        style:  currency ? "currency" : "decimal",
        currency: currency || null,
      })
    : new Intl.NumberFormat(locale);

  return formatter.format(value);
};

