import React from 'react';
import PropTypes from 'prop-types';

export class SvgIco extends React.PureComponent {
  static propTypes = {
    name: PropTypes.string.isRequired,
    size: PropTypes.number,
    height: PropTypes.number,
    width: PropTypes.number,
  };

  static defaultProps = {
    size: 18,
  };

  render() {
    const { size, height, width, name, onClick, className } = this.props;

    // Use id's from assets/images/common-icons.svg
    return (
      <svg
        className={className || ''}
        onClick={onClick || null}
        width={width ? width : size}
        height={height ? height : size}
        id={`svg-${name}`}
      >
        <use href={`#common-icons-${name}`} />
      </svg>
    );
  }
}

export const KeyboardArrowRightRounded = ({ size }) =>
  <SvgIco name="keyboard-arrow-right-rounded" size={size} />;

export const KeyboardArrowLeftRounded = ({ size }) =>
  <SvgIco name="keyboard-arrow-left-rounded" size={size} />;

export const KeyboardArrowRightRoundedIos = ({ size }) =>
  <SvgIco name="keyboard-arrow-right-rounded-ios" size={size} />;

export const KeyboardArrowLeftRoundedIos = ({ size }) =>
  <SvgIco name="keyboard-arrow-left-rounded-ios" size={size} />;

export const Star = ({ size }) => (
  <img
    alt="Star"
    height={size || 20}
    width={size || 20}
    src={require('../../src/images/icons/star.svg')}
  />
);

export const StarBorderIcon = ({ size }) => (
  <img
    alt="Outlined Star"
    height={size || 20}
    width={size || 20}
    src={require('../../src/images/icons/outlined-star.svg')}
  />
);

export const StarHalfIcon = ({ size }) => (
  <img
    alt="Half Star"
    height={size || 20}
    width={size || 20}
    src={require('../../src/images/icons/half-star.svg')}
  />
);

export const DetailedViewSet = {
  videos: <SvgIco name="video" size={20} />,
  photos: <SvgIco name="photos" size={20} />,
  'floor-plans': <SvgIco name="floor-plan" size={20} />,
  virtual_tour: <SvgIco name="virtual-tour" size={20} />,
  map: <SvgIco name="map_detail" size={20} />,
};

export const SocialIcons = {
  facebook: <SvgIco name="facebook-sign" />,
  twitter: <SvgIco name="twitter-sign" />,
  linkedin: <SvgIco name="linkedin-sign" />,
  google: <SvgIco name="google-sign" />,
};
