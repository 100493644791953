export function handleResponse(response) {

  return  response.data;
}

export function handleError(error, returnError) {

  const errorData = error.response || error.data || error;
  const { status, statusText, data } = errorData;

  console.log(error);

  return returnError ? { error: true, status, statusText, data } : null;
}
