import axios from 'axios';
import { handleResponse, handleError } from './response';


export const x_csrf_token = document.querySelector('meta[name="csrf-token"]') ? document.querySelector('meta[name="csrf-token"]').getAttribute('content') : '';

axios.defaults.headers.common = {
  'Content-Type': 'application/json',
  'X-Requested-With': 'XMLHttpRequest',
  'X-CSRF-TOKEN': x_csrf_token
};


const withOptions = (options, returnError) =>
  axios(options)
    .then(response => handleResponse(response))
    .catch(error => handleError(error, returnError));

const getAll = (url, returnError) =>
  axios
    .get(url)
    .then(response => handleResponse(response))
    .catch(error => handleError(error, returnError));

const post = (url, params, returnError, signal) =>
  axios
    .post(url, params, { signal })
    .then(response => handleResponse(response))
    .catch(error => handleError(error, returnError));

const put = (url, params, returnError) =>
  axios
    .put(url, params)
    .then(response => handleResponse(response))
    .catch(error => handleError(error, returnError));

const remove = (url, returnError) =>
  axios
    .delete(url)
    .then(response => handleResponse(response))
    .catch(error => handleError(error, returnError));

export const apiProvider = {
  withOptions,
  getAll,
  post,
  put,
  remove,
};
