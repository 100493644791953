import param from 'jquery-param';
import axios from 'axios';
import FormMapper from '../utils/FormMapper';

// TODO: check X-CSRF-TOKEN
export const x_csrf_token = document.querySelector('meta[name="csrf-token"]') ? document.querySelector('meta[name="csrf-token"]').getAttribute('content') : '';

axios.defaults.headers.common = {
  'Content-Type': 'application/json',
  'X-Requested-With': 'XMLHttpRequest',
  'X-CSRF-TOKEN': x_csrf_token
};

export const fetchLocations = (url, options) => {
  const url_with_params = `${url}?${param(options)}`;
  return axios.get(url_with_params);
};

export const fetchSimilarLocations = endPoint => {
  return axios.get(endPoint);
};

export const placeOrder = (url, params) => {
  //TODO: Add logic for event tracking with analytic
  const data = FormMapper.mapToApiData(params, []);
  return axios.post(url, data);
};

export const placeOrderDetails = (url, params) => {
  //TODO: Add logic for event tracking with analytic
  const data = FormMapper.mapToOrderDetailsApiData(params, []);
  return axios.post(url, data);
};

export const showCase = (url, params) => {
  const data = FormMapper.mapToShowCaseParameters(params);
  return axios.post(url, data);
};

export const placeUncompletedOrder = (url, data) => {
    return axios.post(url, {uncompleted_order: data});
};

export const contactProvider = (url, params) => {
  const data = FormMapper.mapToContactWithProviderParameters(params);
  return axios.post(url, data);
};

export const getParkingLotsNear = (latitude, longitude, radius_in_km = 3) => {
  return axios.get('/lease/api/frontend/parking_lots', {
    params: {
      kind: 'all',
      latitude,
      longitude,
      radius_in_km,
    },
  });
};

export const validationFail = (url, params) => {
    const data = FormMapper.mapValidationFailParameters(params);
    return axios.post(url, data);
};

export const postLocationShown = (location_id) => {
  return axios.post('/lease/api/frontend/locations/shown', {
    id: location_id
  });
};

export const postLocationContactDetailsShown = (locationId) => {
  return axios.post('/lease/api/frontend/location_events', {
    location_id: locationId,
    type: "contact_details_shown"
  });
};

export const postLocationProviderAdvertsShown = (locationId) => {
  return () => {
    axios.post('/lease/api/frontend/location_events', {
      location_id: locationId,
      type: "provider_adverts_shown"
    });
  }
};

export const translateDescription = (description, title, sourceLocale, targetLocale) => {
    return axios.post('/lease/api/frontend/locations/translate_description', {
        description: description,
        title: title,
        source_locale: sourceLocale,
        target_locale: targetLocale
    });
};
