import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import ujs from 'react_ujs';

class RootComponent extends Component {

  componentDidMount() {
    this.childComponentsMap.forEach(child => {
      this.renderChildComponents(child.dom_container_id, child.component, child.props);
    })
  }

  renderChildComponents = (domContainerId, component, props) => {
    const nodeEp = document.getElementById(domContainerId);
    const constructorEp = ujs.getConstructor(component);

    ReactDOM.render(React.createElement(constructorEp, props), nodeEp);
  };

  render() {
    return (
      <div>
        { this.childComponentsMap.map((child, index) => <div id={child.dom_container_id} key={index} /> )}
      </div>
    )
  }
}

export default RootComponent;
