import React, { Component } from 'react';

import { SvgIco } from "../Icons";

import './index.sass';

class Notification extends Component {
  state = {
    animationClass: '',
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.props.visible !== prevProps.visible && this.setState({ animationClass: this.props.visible ? 'fadein' : 'fadeout' })
  }

  render() {

    const { customClass, handleClose, message } = this.props;

    return (
      <div className={`notification ${this.state.animationClass} ${customClass || ''}`} role="button">
        <div
          className="notification__close-wrapper"
          onClick={handleClose}
          role="button"
          aria-labelledby="close-notification"
        >
          <SvgIco name="close" size={20} />
        </div>
        <div dangerouslySetInnerHTML={{ __html: message }} />
      </div>
    )
  }
}

export default Notification;
