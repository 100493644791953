export const mappedMonths = (monthNumber) => {
  let monthsList = {
    1: "januar",
    2: "februar",
    3: "marts",
    4: "april",
    5: "maj",
    6: "juni",
    7: "juli",
    8: "august",
    9: "september",
    10: "oktober",
    11: "november",
    12: "december",
  }

  return monthsList[monthNumber]
}