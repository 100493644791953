import React from 'react';

export const loadTawkTo = (siteKey, onloadFunction) => {
  let tawkSrc;
  const siteWithTawk = ['dk', 'global', 'de'];

  switch (siteKey) {
    case 'dk':
      tawkSrc = 'https://embed.tawk.to/5fff0cb0a9a34e36b96c09a9/1eru4j4l0';
      break;
    case 'de':
      tawkSrc = 'https://embed.tawk.to/62ecd96b54f06e12d88d0eb2/1g9mj2jr9';
      break;
    default:
      tawkSrc = 'https://embed.tawk.to/5fff0c26a9a34e36b96c096e/1eru4etqf';
  }

  siteWithTawk.includes(siteKey) &&
    setTimeout(function () {
      const Tawk_API = Tawk_API || {}, Tawk_LoadStart = new Date();
      (function () {
        const s1 = document.createElement("script"), s0 = document.getElementsByTagName("script")[0];
        s1.async = true;
        s1.src = tawkSrc;
        s1.charset = 'UTF-8';
        s1.setAttribute('crossorigin', '*');
        s0.parentNode.insertBefore(s1, s0);
        s1.onload = () => {
          onloadFunction && onloadFunction();
        }
      })();
    }, 10000);
}


