import { handleFavoriteLocationsCounter } from './topBarHelper';


export const updateFavorites = (locationId, section, siteKey, bounceFunction) => {
  const locationsLimit = 50;
  const locationIdStr = `${locationId}`;
  const locationType = section === 'lease' ? section : 'sale';
  const favouriteLocations = (localStorage && JSON.parse(localStorage.getItem(`my.favourite_${locationType}_${siteKey}`))) || [];
  const locationIsInTheList = favouriteLocations.length && favouriteLocations.indexOf(locationIdStr) >= 0;
  const limitExceededError = favouriteLocations.length && favouriteLocations.length === locationsLimit && !locationIsInTheList;

  if (limitExceededError) {
    return { limitExceededError };
  } else {
    if (locationIsInTheList) {
      favouriteLocations.splice(favouriteLocations.indexOf(locationIdStr), 1);
    } else {
      bounceFunction && bounceFunction(locationId);
      favouriteLocations.unshift(locationIdStr);
    }

    localStorage.setItem(`my.favourite_${locationType}_${siteKey}`, JSON.stringify(favouriteLocations));
    handleFavoriteLocationsCounter(siteKey, !locationIsInTheList);

    return { favouriteLocations };
  }
};
